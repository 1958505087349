"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTimestamp = exports["default"] = void 0;
// This is .js for preval

var versionType = "PROD";
var versionNumber = Math.floor(1720427279317 / 1000);
var shortCommitSha = "9cba4d74e";
var getTimestamp = exports.getTimestamp = function getTimestamp(version) {
  return +version.split('-')[1];
};
var _default = exports["default"] = "PROD-1720427279-9cba4d74e";